document.addEventListener("turbo:load", () => {
  loadInstagramEmbeds();
});

function loadInstagramEmbeds() {
  if (window.instgrm) {
    window.instgrm.Embeds.process();
  } else {
    setTimeout(loadInstagramEmbeds, 100);
  }
}

