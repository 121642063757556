document.addEventListener("turbo:load", () => {
  loadTwitterWidgets();
});

function loadTwitterWidgets() {
  if (window.twttr) {
    window.twttr.widgets.load();
  } else {
    setTimeout(loadTwitterWidgets, 100);
  }
}
