import autoComplete from "@tarekraafat/autocomplete.js";

const AVATAR_CLASSES = "mr-2 relative inline-block h-6 w-6 rounded-full ring-2 ring-gray-50 bg-gray-50";

document.addEventListener("turbo:load", () => {
  initAutocomplete();
});

function initAutocomplete() {
  if (window.Alpine.pauseMutationObserver) {
    // alpine turbolinks is still working
    setTimeout(initAutocomplete, 5);
    return;
  }
  
  let el = document.querySelector(".js-autocomplete");
  if (!el) return; 

  let userUrl = el.dataset.searchUrl;

  let component = document.querySelector('.autocomplete-component')._x_dataStack;

  new autoComplete({
    data: {
      src: async() => {
        const query = el.value;
        const source = await fetch(`${userUrl}?q=${query}`);
        return await source.json();
      },
      keys: ["username"],
      cache: false
    },
    placeHolder: "Type to search...",
    selector: ".js-autocomplete",
    threshold: 3,
    resultsList: {
      destination: ".autocomplete-wrapper",
      position: "beforeend",
      tag: "ul",
      class: "fever-autocomplete"
    },
    resultItem: {                          
      tag: "li",
      class: "flex items-center",
      highlight: "bg-yellow-100 rounded-md",
      selected: "bg-gray-200",
      element: (source, data) => {
        let image = document.createElement("img")
        image.setAttribute("class", AVATAR_CLASSES);
        image.setAttribute("src", data.value.avatar_url);

        source.insertAdjacentElement("afterbegin", image);
      },
    },
    maxResults: 10
  })

  document.querySelector(".js-autocomplete").addEventListener("selection", function (event) {
    let { selection } = event.detail;
    let user = selection.value;
    component[0].recipients.push(user);

    event.target.value = "";
    
    return false;
  });
}
