window.quotePost = ($dispatch, postId) => {
  let quoteText = document.querySelector(`#post_${postId} .quote-content`).innerHTML;

  $dispatch('quote-post', {
    quoteText
  })
}

window.setEditorContent = (text) => {
  let element = document.querySelector("trix-editor");
  if (element) {
    element.editor.setSelectedRange([0, 0])
    element.editor.insertHTML(text);
  }
}