document.addEventListener("turbo:load", () => {
  // need this delay for some reason, js loading I guess?
  setTimeout(() => {
    let target = null 
    let params = (new URL(document.location)).searchParams;
    
    if (window.location.hash) {
      target = document.getElementById(window.location.hash.slice(1));
    } else if (params.get("highlight")) {
      target = document.getElementById(params.get("highlight"));
    }

    if (target) {
      target.scrollIntoView(true);
      window.scrollBy(0, -20);

      target.classList.add("ring-2", "ring-cyan-500", "duration-1000", "transition-all")
      setTimeout(() => {
        target.classList.remove("ring-2", "ring-cyan-500")
      }, 3000);
    }
  }, 25);
});