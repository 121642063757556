// previews the selected image when a file field changes
// used for avatars on user#edit form
window.previewImage = (changeEvent, previewElement) => {
  let input = changeEvent.target;
  let reader = new FileReader();

  reader.onload = function (e) {
    previewElement.src = e.target.result;
  }

  reader.readAsDataURL(input.files[0]); 
}