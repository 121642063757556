/* eslint no-console:0 */

import Rails from "@rails/ujs"
import * as Turbo from "@hotwired/turbo"

Rails.start()
Turbo.start()

import Alpine from 'alpinejs'

import "./scripts"

window.Alpine = Alpine
Alpine.start()