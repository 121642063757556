import Trix from 'trix';

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'];
const maxFileSize = 1024 * 1024 * 100// 100MB 

// video code from https://github.com/basecamp/trix/issues/591

document.addEventListener("turbo:load", () => {
  let trixDialogs = document.querySelector("[data-trix-dialogs]");
  if (trixDialogs) {
    document.querySelector("[data-trix-dialog-submit--video]").addEventListener('click', insertVideo)
  }
});

document.addEventListener("trix-file-accept", function (event) {
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault();
    alert(`Sorry, we don’t support ${event.file.type} uploads`)
    return
  }

  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("That file is quite big, maybe find a smaller version?")
    return
  }
})

// Lol adapted from SO code: https://stackoverflow.com/questions/53835949/rails-5-2-trix-activestorage
document.addEventListener('trix-attachment-add', function (event) {
  let file = event.attachment.file;
  if (file) {
    let upload = new ActiveStorage.DirectUpload(file, '/rails/active_storage/direct_uploads');

    addEventListener("direct-upload:progress", (event) => {
      let progress = event.loaded / event.total * 100;
      return event.attachment.setUploadProgress(progress);
    })

    upload.create((error, attributes) => {
      if (error) {
        return false;
      } else {
        let editor = event.target;
        let field = document.querySelector(`input#${editor.getAttribute("attachment_name")}_blob_ids`);
        let ids = field.value.split(",").filter(x => x != "");
        ids.push(attributes.id);
        field.value = ids.join(",");

        return event.attachment.setAttributes({
          url: `/rails/active_storage/blobs/${attributes.signed_id}/${attributes.filename}`,
          href: `/rails/active_storage/blobs/${attributes.signed_id}/${attributes.filename}`,
        });
      }
    });
  }
});

document.addEventListener("trix-focus", (e) => {
  let container = e.target.closest(".trix-container");
  if (container) container.classList.add("focus")
});

document.addEventListener("trix-blur", (e) => {
  let container = e.target.closest(".trix-container");
  if (container) container.classList.remove("focus");
});

// document.addEventListener("trix-action-invoke", event => {
//   if (event.actionName == "x-custom-quote") {
//     const { editor } = event.target

//     let attachment = new Trix.Attachment({ 
//       content: `<forum-quote><author>Someone</author>Click to edit...</trix-editor></forum-quote>`, 
//       contentType: 'quote' 
//     })
//     editor.insertAttachment(attachment)
//   }
// })

function insertVideo(event) {
  let editor = document.querySelector("trix-editor").editor; // won't work with multiple editors on page!
  let videoElement = document.querySelector("[data-trix-dialog=video] input[name=video]");
  
  if (videoElement.value) {
    let youtubeId = '';
    let url = videoElement.value.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      youtubeId = url[2].split(/[^0-9a-z_\-]/i);
      youtubeId = youtubeId[0];
    } else {
      youtubeId = url;
    }

    let videoEmbed = `<iframe width="420" height="315" src="https://www.youtube.com/embed/${youtubeId}" frameborder="0" allowfullscreen></iframe>`

    editor.selectionManager.unlock();
    editor.insertAttachment(new Trix.Attachment({ content: videoEmbed }));
  }
}

const OBJECT_URL_MATCHER = RegExp(/https?:\/\/twitter\.com\/.+\/status\/[0-9]{8,32}(\?.*)?|https:?\/\/[w]{0,3}\.?instagram\.com\/p\/[A-z]+\/?(\?.*)?/, 'g')
const oembedsUrl = "/oembeds";

document.addEventListener("trix-paste", async function(event) {
  if (event.paste && (event.paste.string || event.paste.html)) {

    let pastedString = event.paste.string;

    if (!pastedString && event.paste.html) {
      pastedString = event.paste.html.replace(/(<([^>]+)>)/ig, '');
    }

    let pasteStart = event.paste.range[0];
    let match;
    let offset = 0;

    while ((match = OBJECT_URL_MATCHER.exec(pastedString)) !== null) {
      let objectUrl = match[0];

      let rangeStart = pasteStart + match.index + offset;
      let rangeEnd = rangeStart + objectUrl.length;

      let response = await fetch(`${oembedsUrl}?url=${objectUrl}`);

      if (response.status == 200) {
        let json = await response.json();

        let editor = document.querySelector("trix-editor").editor;
        editor.selectionManager.unlock();
        editor.setSelectedRange([rangeStart, rangeEnd])

        let contentType; 
        if (json.provider == "twitter") contentType = "tweet";
        if (json.provider == "instagram") contentType = "instagram_post";

        editor.insertAttachment(new Trix.Attachment({ content: json.html, contentType }));

        // we've removed the URL and added an attachment
        // which trix counts as one character. So this maintains the
        // right position if there are multiple URLs in a paste
        offset = offset - objectUrl.length + 1;

        window.twttr.widgets.load();
        window.instgrm.Embeds.process();
      }
    }
  }
})