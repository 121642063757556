document.addEventListener("turbo:load", () => {
  initUnreadPosts();
});

function initUnreadPosts() {
  if (window.Alpine.pauseMutationObserver) {
    // alpine turbolinks is still working
    setTimeout(initUnreadPosts, 5);
    return;
  }
  
  let container = document.querySelector("#topic_list");

  if (container && container.dataset.newPostsByTopic) {
    let postData = JSON.parse(container.dataset.newPostsByTopic);

    for (const [topicId, { count, last_post_id, permalink }] of Object.entries(postData)) {
      if (count > 0) {
        let topicComponent = container.querySelector(`tr#topic_${topicId}`)._x_dataStack;

        if (topicComponent) {
          topicComponent[0].unread = true;
          topicComponent[0].count = count;
          topicComponent[0].lastPostId = last_post_id;
          topicComponent[0].permalink = permalink;
        }
      }
    }
  } 

}