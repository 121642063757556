import Rails from "@rails/ujs";

document.addEventListener("fever:endorse-post", (event) => {

  let [_callback, component, _rest ] = event.detail;

  if (component.isOwnPost) {
    // can't endorse own post
    return;
  }

  let type = (component.hasEndorsed) ? "DELETE" : "POST";
  component.hasEndorsed = !component.hasEndorsed;

  Rails.ajax({
    url: component.endorsementUrl, 
    type
  });

})